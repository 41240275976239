import { regions } from './constants'

export const eventIds = {
  deepakChopra: '1',
  markHyman: '2',
  philipGoldberg: '4',
  kunalNayyar: '5',
  terryTamminen: '6',
  deepakChopraAu: '7',
  markHymanAu: '8',
  kunalNayyarAu: '9',
  clubhouse: '10',
  questionsOnKarma: '11',
}

const enhanceHardcodedEventData = (data) => {
  return Object.entries(data).reduce((acc, [key, item]) => {
    acc[key] = {
      ...item,
      eventPricing: {
        US: '$30',
        CA: 'CA-$38',
        [regions.southAmerica]: 'US-$21',
        [regions.oceania]: '$20',
      },
      isPast: true,
    }
    return acc
  }, {})
}

const deepakChopraEvent = {
  id: '1',
  duration: '60 min.',
  price: '$35',
  customStripe: 'DeepakChopra',
  name: 'Deepak Chopra',
  twitter: '@DeepakChopra',
  title: 'Karma: Craft Your Destiny',
  subText: 'Sadhguru with Deepak Chopra',
  dateText: 'April 29th at 4PM PDT / 7PM EDT',
  image:
    'https://www.innerengineering.com/lib/img/karma-book/live-events/deepakChopraEvent.jpg',
  slug: 'deepak-chopra',
  isRecordedEvent: true,
}

const markHymanEvent = {
  id: '2',
  duration: '75 min.',
  price: '$35',
  customStripe: 'DrMarkHyman',
  name: 'Dr. Mark Hyman',
  twitter: '@drmarkhyman',
  title: 'Karma of Health',
  subText: 'Sadhguru with Dr. Mark Hyman',
  dateText: 'May 6th at 5:30PM PDT /  8:30PM EDT',
  image:
    'https://www.innerengineering.com/lib/img/karma-book/live-events/mark-hyman.jpeg',
  slug: 'mark-hyman',
  questionHashtag: '#KarmaofHealth',
  isRecordedEvent: true,
}

const kunalNayyarEvent = {
  id: '5',
  price: '$35',
  duration: '75 min.',
  customStripe: 'KunalNayyar',
  name: 'Kunal Nayyar',
  twitter: '#KunalNayyar',
  slug: 'kunal-nayyar',
  title: 'Karma Inclusive',
  dateText: 'May 22nd at 4:00PM PDT /  7:00PM EDT',
  subText: 'Live with Kunal Nayyar',
  image:
    'https://www.innerengineering.com/lib/img/karma-book/live-events/kunal-nayyar.jpg',
  questionHashtag: '#KarmaInclusive',
  isRecordedEvent: true,
}

const commonAuEventData = {
  eventWithBookFormId: '211266564956161',
  eventOnlyFormId: '211266295483157',
  isRecordedEvent: true,
  price: '$35',
}

export const eventData = enhanceHardcodedEventData({
  [eventIds.deepakChopra]: deepakChopraEvent,
  [eventIds.markHyman]: markHymanEvent,
  [eventIds.philipGoldberg]: {
    id: '4',
    price: '$35',
    duration: '75 min.',
    customStripe: 'PhilipGoldberg',
    name: 'Philip Goldberg',
    twitter: '@phil_amveda',
    slug: 'philip-goldberg',
    title: 'Karma - Confusion to Clarity',
    dateText: 'May 20th at 4:00PM PDT /  7:00PM EDT',
    subText: 'Live with Philip Goldberg',
    image:
      'https://www.innerengineering.com/lib/img/karma-book/live-events/philip-goldberg.jpg',
    questionHashtag: '#KarmaMemory',
    isRecordedEvent: true,
  },
  [eventIds.kunalNayyar]: kunalNayyarEvent,
  [eventIds.terryTamminen]: {
    id: '6',
    price: '$35',
    duration: '75 min.',
    customStripe: 'TerryTamminen',
    name: 'Terry Tamminen',
    twitter: '@terrytamminen',
    slug: 'terry-tamminen',
    title: 'Earth Karma',
    dateText: 'May 18th at 5:00PM PDT / 8:00PM EDT',
    subText: 'Live with Terry Tamminen',
    image:
      'https://www.innerengineering.com/lib/img/karma-book/live-events/terry-tamminen.jpg',
    questionHashtag: '#EarthKarma',
    isRecordedEvent: true,
  },
  [eventIds.deepakChopraAu]: {
    ...deepakChopraEvent,
    ...commonAuEventData,
    id: eventIds.deepakChopraAu,
    slug: 'deepak-chopra-au',
    customStripe: 'AU-DeepakChopra',
    subText: 'Sadhguru with Deepak Chopra',
    dateText: '27 May (Thu) 8PM AEST / 6PM AWST',
  },
  [eventIds.kunalNayyarAu]: {
    ...kunalNayyarEvent,
    ...commonAuEventData,
    id: eventIds.kunalNayyarAu,
    slug: 'kunal-nayyar-au',
    customStripe: 'AU-KunalNayyar',
    subText: 'Sadhguru with Kunal Nayyar',
    dateText: '30 May (Sun) 7PM AEST / 5PM AWST',
  },
  [eventIds.markHymanAu]: {
    ...markHymanEvent,
    ...commonAuEventData,
    id: eventIds.markHymanAu,
    slug: 'mark-hyman-au',
    customStripe: 'AU-DrMarkHyman',
    subText: 'Sadhguru with Dr. Mark Hyman',
    dateText: '1 Jun (Tue) 8PM AEST / 6PM AWST',
  },
  [eventIds.clubhouse]: {
    id: eventIds.clubhouse,
    price: '$35',
    duration: '60 min.',
    customStripe: 'MasteringYourDestiny',
    name: '',
    twitter: '',
    slug: 'mastering-your-destiny',
    title: 'Karma - Mastering Your Destiny',
    dateText: '',
    subText: '',
    image:
      'https://www.innerengineering.com/lib/img/karma-book/live-events/sadhguru.jpg',
    questionHashtag: '',
    isRecordedEvent: true,
    featured: true,
  },
  [eventIds.questionsOnKarma]: {
    id: eventIds.questionsOnKarma,
    price: '$35',
    duration: '60 min.',
    customStripe: 'QuestionsOnKarma',
    name: '',
    twitter: '',
    slug: 'questions-on-karma',
    title: 'Questions on Karma',
    dateText: '',
    subText: '',
    image:
      'https://www.innerengineering.com/lib/img/karma-book/live-events/questions-on-karma.jpg',
    questionHashtag: '',
    isRecordedEvent: true,
    featured: true,
  },
})
